.aMember {
    background-color: white;
    padding: 0;
    border-radius: 0;
    box-shadow: none;
    border-bottom: 0;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    margin-bottom: 20px; 
    height: auto;
    width: 320px;
    @include media-breakpoint-up(md) {
        align-items: center;
        justify-content: flex-start;
        flex-direction: row;
        height: 430px; // photo height
        width: 620px;
    }
    @include media-breakpoint-up(lg) {
        height: 300px;
        width: auto;
    }
    a {
        &:hover {
            text-decoration: none;
        }
    }
    &__details-wrapper {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-between;
        padding: 20px; 
        flex-grow: 1;
        height: 100%;
        @include media-breakpoint-up(md) {
            padding: 30px;
            width: 390px;
        }
        @include media-breakpoint-up(lg) {
            padding: 40px;
            flex-direction: row;
            align-items: center;
            width: auto;
        }
    }
    &__left-column{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-between;
        height: auto;
        width: 100%;
        @include media-breakpoint-up(md) {
            height: auto;
        }
        @include media-breakpoint-up(lg) {
            height: 185px; // QR code height
            padding-right: 20px;
        }
    }
    &__ctas-wrapper {
        padding: 20px 0;
        a {
            display: block;
            text-align: left;
            margin-bottom: 5px;
            font-size: 13px;
            line-height: 24px;
            color: black;
            @include media-breakpoint-up(md) {
                font-size: 15px;
            }
            @include media-breakpoint-up(lg) {
                font-size: 16px;
            }
            span {
                color: $c-gold;
                margin-right: 10px;
            }
            &:last-child {
                margin-bottom: 0;
            }
        }
        @include media-breakpoint-up(md) {
            
        }
        @include media-breakpoint-up(lg) {
            padding: 0;
        }
    }
    &__title {
        margin-bottom: 0;
        color: $c-gold;
        font-size: 18px;
        line-height: 22px;
        font-family: 'Gotham Bold';
        text-align: left;
        @include media-breakpoint-up(md) {
            font-size: 21px;
            line-height: 24px;
        }
        @include media-breakpoint-up(lg) {
            font-size: 22px;
            line-height: 25px;
            max-width: 510px;
        }
    }
    &__name {
        color: black;
        margin-bottom: 10px;
        font-size: 35px;
        line-height: 40px;
        font-family: 'Amiri';
        font-weight: bold;
        margin: 0;
        @include media-breakpoint-up(md) {
            font-size: 45px;
            line-height: 50px;
            max-width: 300px;
        }
        @include media-breakpoint-up(lg) {
            max-width: 480px;
        }
    }
    &__photo {
        width: 320px;
        height: 230px;
        background-size: cover;
        background-position: center -20px;
        background-repeat: no-repeat;
        @include media-breakpoint-up(md) {
            background-position: center;
            height: 430px;
            width: 231px;
        }
        @include media-breakpoint-up(lg) {
            width: 288px;
            height: 300px;
        }
    }
    &__email {
        &.long-email {
            @include media-breakpoint-down(sm) {
                line-height: 16px;
            }
        }
        
    }   
    a.qrcode-link {
        @include media-breakpoint-up(md) {
           
        }
        img {
            width: 80px;
            height: 80px;
            @include media-breakpoint-up(md) {
                width: 100px;
                height: 100px;
            }
            @include media-breakpoint-up(lg) {
                width: 185px;
                height: 185px;
            }
        }
    }
}
br.mobile-only {
    display: block;
    @include media-breakpoint-up(md) {
        display: none;
    }
}
.team-member-detail-wrapper {
    background-color: white;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    max-width: 100%;
    width: 320px;
    padding: 0;
    @include media-breakpoint-up(md) {
        flex-direction: row;
        justify-content: flex-start;
        align-items: flex-start;
        width: 100%;
    }
    @include media-breakpoint-up(lg) {
        
    }
    p {
        margin: 0;
        width: 100%;
        text-align: left;
    }
    a {
        color: black;
        text-decoration: none;
        text-align: center;
    }
    div.photo {
        background-position: top;
        background-repeat: no-repeat;
        background-size: cover;
        width: 100%;
        flex-grow: 1;
        height: 220px;
        @include media-breakpoint-up(md) {
            background-position: center;
            width: 280px;
            height: 430px;
        }
        @include media-breakpoint-up(lg) {
            width: 290px;
            height: 300px;
        }
    }
    div.ipad-wrap {
        padding: 20px 20px 40px 20px;
        width: 100%;
        position: relative;
        @include media-breakpoint-up(md) {
            padding: 30px;
            height: 430px;
        }
        @include media-breakpoint-up(lg) {
            height: 300px;
            padding: 0;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
        }
    }
    div.all-buttons-wrap {
        @include media-breakpoint-up(lg) {
            
            padding: 40px;
        }
    }
    div.info-wrap {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        width: 100%;
        margin-bottom: 20px;
        @include media-breakpoint-up(lg) {
            margin-bottom: 0;
        }
        h1.name {
            font-size: 35px;
            line-height: 40px;
            text-align: left;
            color: black;
            font-family: 'Amiri';
            margin-bottom: 10px;
            @include media-breakpoint-up(md) {
                font-size: 45px;
                line-height: 50px;
                margin-bottom: 0;
                width: 295px;
            }
            @include media-breakpoint-up(lg) {
                width: auto;
            }
        }
        .title {
            font-size: 18px;
            line-height: 22px;
            color: $c-gold;
            margin-bottom: 20px;
            @include media-breakpoint-up(md) {
                font-size: 21px;
                line-height: 24px;
            }
            @include media-breakpoint-up(lg) {
            }
           
        }
        p {
            font-size: 13px;
            line-height: 24px;
            word-break: break-all;
            margin-bottom: 0;
            @include media-breakpoint-up(md) {
                font-size: 15px;
                line-height: 24x;
                
            }
            @include media-breakpoint-up(lg) {
            }
            span {
                color: $c-gold;
                margin-right: 10px;
            }
            &.long-email {
                @include media-breakpoint-down(sm) {
                    line-height: 16px;
                }
            }
        }
        @include media-breakpoint-up(lg) {
            padding: 40px;
        }
    }
    div.cta-wrap {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        @include media-breakpoint-up(md) {
        }
        a {
            background-color: transparent;
            border: solid 1px $c-gold;
            text-transform: uppercase;
            font-size: 14px;
            line-height: 14px;
            padding: 10px 20px;
            width: 128px;
            @include media-breakpoint-up(md) {
                font-size: 11px;
                line-height: 9px;
                width: 140px;
            }
            @include media-breakpoint-up(lg) {
                font-size: 15px;
                line-height: 11px;
            }
        }
    }
    div.cta-wrap-2 {
        width: 100%;
        margin-bottom: 20px;
        @include media-breakpoint-up(md) {
            margin-top: 10px;
            margin-bottom: 10px;
        }
        @include media-breakpoint-up(lg) {
            width: 300px;
            margin-bottom: 20px;
        }
        .clear-button {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            background-color: transparent;
            border: solid 1px $c-gold;
            border-left: 0;
            border-right: 0;
            margin-bottom: 0;
            padding: 10px 0;
            text-transform: uppercase;
            font-size: 14px;
            line-height: 1;
            &:first-child {
                border-bottom: 0;
            }
            &:hover {
                cursor: pointer;
            }
            @include media-breakpoint-up(md) {
                font-size: 11px;
                line-height: 11px;
            }
            @include media-breakpoint-up(lg) {
                padding: 15px 0;
                font-size: 15px;
                line-height: 14px;
            }
            img {
                height: 18px;
                width: auto;
            }
        }
    }
}
.back-button {
    color: white;
    text-decoration: none;
    font-family: 'Gotham Book';
    font-size: 14px;
    line-height: 11px;
    text-align: center;
    display: block;
    width: 100%;
    margin: 50px 0;
    &:visited {
        color: white;
    }
    &:hover {
        text-decoration: none;
    }
    span, p {
        display: inline-block;
    }
}
.copied {
    background-color: $c-gold;
    padding: 10px;
    opacity: 0;
    position: absolute;
    animation: myFadeIn 3s;
    font-family: 'Gotham Book';
    text-align: center !important;
    font-size: 14px;
    line-height: 1;
    bottom: 0;
    width: 100%;
    left: 0;
    right: 0;
}
.map-container {
    height: 190px;
    width: 320px;
    @include media-breakpoint-up(md) {
        width: 100%;
    }
    @include media-breakpoint-up(lg) {
        height: 375px;
    }
}
@keyframes myFadeIn {
    0% { opacity: 0; }
    50% { opacity: 0.9 }
    100% { opacity: 0; }
}
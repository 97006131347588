@font-face {
    font-family: 'Amiri';
    src: url('../assets/fonts/Amiri-Bold.ttf') format('ttf');
    font-style: normal;
    font-weight: 'bold';
    font-display: swap;
    text-rendering: optimizeLegibility;
}
@font-face {
    font-family: 'Gotham Bold';
    src: url('../assets/fonts/Gotham-HTF-Bold-2.ttf') format('truetype');
    font-style: normal;
    font-weight: 'bold';
    font-display: swap;
    text-rendering: optimizeLegibility;
}
@font-face {
    font-family: 'Gotham Book';
    src: url('../assets/fonts/Gotham-HTF-Book.ttf') format('truetype');
    font-style: normal;
    font-weight: 'bold';
    font-display: swap;
    text-rendering: optimizeLegibility;
}

body {
    background-color: black;
    font-family: 'Gotham Bold';
}
h1 {
    width: 100%;
    text-align: center;
    font-weight: bold;
    color: $c-gold;
}
a {
    &:hover {
        text-decoration: none;
    }
}
.header, .footer {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: black;
    padding: 70px 30px;
}
.header {
    justify-content: center;
    align-items: center;
    .container {
        text-align: center;
        @media(min-width: 1920px) {
            max-width: 1380px;
            position: relative;
        }
    }
    img {
        width: 150px;
        margin: auto;
    }
    @include media-breakpoint-up(md) {
        padding-left: 0;
        padding-top: 50px;
        img {
            width: 200px;
        }
    }
    @include media-breakpoint-up(lg) {
        padding: 70px 0;
        img {
            width: 250px;
        }
    }    
}
.footer {
    padding: 40px 30px 20px 30px;
    flex-direction: column-reverse;
    @include media-breakpoint-up(md) {
        flex-direction: row;        
    }
    a {
        margin-right: 10px;
        font-family: 'Gotham Book';
        img {
            height: 35px;
        }
    }
    p {
        font-size: 10px;
        color: white;
        font-family: 'Gotham Book';
        @include media-breakpoint-up(md) {
            margin-right: 20px;
        }
    }
}
.footer-links {
    @include media-breakpoint-up(md) {
        margin-left: 20px;
    }
    ul {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        padding: 0;
        li {
            list-style-type: none;
            font-size: 10px;
            border-right: solid 1px grey;
            a {
                margin: 0 5px;
                color: white;
                text-decoration: none;
            }
            &:first-of-type {
                padding-right: 5px;
            }
            &:last-of-type {
                border-right: none;
            }
        }
    }
}
.page-content {
    min-height: 400px;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    @include media-breakpoint-up(md) {
        min-height: calc(100vh - 400px);
    }
    h1 {
        margin-bottom: 40px;
        color: cadetblue;
    }
    .container {
        padding: 0 30px;
        margin: auto;
        @include media-breakpoint-up(md) {
            padding: 0;
        }
        @include media-breakpoint-up(lg) {
            position: relative;
        }
        @media(min-width: 1320px) {
            max-width: 1240px;
        }
    }
}
// .search-bar {
//     padding: 0;
//     width: 100%;
//     display: flex;
//     justify-content: space-between;
//     flex-direction: row;
//     margin-bottom: 40px;
//     input {
//         border-radius: 40px;
//         border: none;
//         padding: 10px 20px;
//         margin-right: 20px;
//         width: calc(100% - 80px);
//         background-image: url('../assets/img/search-icon.png');
//         background-position: 15px center;
//         background-size: 22px 22px;
//         background-repeat: no-repeat;
//         padding-left: 48px;
//         font-size: 16px;
//         line-height: 1;
//         &::placeholder {
//             font-style: italic;
//         }
//     }
//     button {
//         border: none;
//         text-decoration: underline;
//         background-color: transparent;
//         // font-family: $GraphikRegular;
//         font-size: 14px;
//         line-height: 1;
//         color: black;
//         &:hover {
//             font-style: italic;
//         }
//     }
//     @include media-breakpoint-up(md) {
//         position: absolute;
//         top: 40px;
//         right: calc($ipad-width / 2);
//         width: 380px;
//     }
//     @include media-breakpoint-up(lg) {
//         top: 60px;
//         right: calc($desktop-width / 2);
//         width: 380px;
//     }
// }

.ebusinesscard-page {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    .container {
        @include media-breakpoint-up(lg) {
            position: static;
        }
    }
}
.loading-container {
    position: static !important;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}